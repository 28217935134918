/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { FormattedMessage, useIntl, injectIntl } from "react-intl";
import { Field, FormikProvider, useFormik } from "formik";
import { Link } from "@material-ui/core";
import * as Yup from "yup";
import {
  completeProfile,
  forgetPasswordPincode,
  forgetPasswordPincodeVerification,
  login,
  loginOTP,
  pinCodeVerification,
  resendCode,
  resendCodeForgetPass,
  resetPasswordPincode,
  signUp,
  validateLoginOtp,
  validateLoginPass,
} from "../../../../app/modules/Auth/_redux/authCrud";
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";
import { useHistory, Redirect } from "react-router-dom";
import {
  Encrypt,
  EncryptOTP,
  phoneRegExp,
  phoneRegExp2,
  sendRequestDotNet,
} from "../../../../_utils/Axios";
import { PinInput } from "react-input-pin-code";
import { DatePickerField, Input } from "../../../_partials/controls";
// import { login } from "../../../../app/modules/Auth/_redux/authRedux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { KTCookie } from "../../../_assets/js/components/cookie";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { setLogin } from "../../../../app/modules/UserProfile/_redux/Profile/profileActions";

function Login(props) {
  // console.log(auth)
  const { user } = useSelector((state) => state.auth, shallowEqual);

  const loginCheck = useSelector(
    (state) => state.profileSlice.login,
    shallowEqual
  );
  const uiService = useHtmlClassService();
  const history = useHistory();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);
  const [show, setShow] = useState(false);
  const [showSignUp, setSignUp] = useState(false);
  const [showVerifyNbr, setVerifyNbr] = useState(false);
  const [showCreatePass, setCreatePass] = useState(false);
  const [showCompleteProfile, setCompleteProfile] = useState(false);
  const [showLoggingIn, setLoggingIn] = useState(false);
  const [showValidateOTP, setValidateOTP] = useState(false);
  const [idRequest, setIdRequest] = useState("");
  const [pinValues, setPinValues] = useState(["", "", "", ""]);
  const [pinValuesForgetPass, setPinValuesForgetPass] = useState([
    "",
    "",
    "",
    "",
  ]);

  const [showForgetPass, setForgetPass] = useState(false);
  const [showForgetPassVerify, setForgetPassVerify] = useState(false);
  const [showCreatePassForgetPass, setCreatePassForgetPass] = useState(false);

  const [msisdnVal, setmsisdn] = useState("");
  const [uid, setUID] = useState("");
  const [userID, setUserID] = useState("");
  const [locations, setLocations] = useState([]);
  const [genders, setGenders] = useState([]);

  const [passwordVal, setPassword] = useState("");
  const [confirmPasswordVal, setConfirmPassword] = useState("");

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      backgroundColor: "#F3F6F9",
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      width: "100%",
      borderRadius: "8px",
      padding: "10px",
    },
  }));

  const initialValues = {
    email: "",
    password: "",
  };
  const initialValuesPass = {
    password: "",
    confirmPassword: "",
  };

  const initialValuesPin = {
    msisdn: "",
    pincode: ["", "", "", ""],
  };
  const initialValuesResendCodeForgetPass = {
    msisdn: "",
  };

  const initialValuesCompleteProfile = {
    firstName: "",
    lastName: "",
    fatherName: "",
    email: "",
    password: "",
    confirmPassword: "",
    dateOfBirth: "",
    genderId: "",
    idLocation: "",
    invitationCode: "",
    uid: "",
  };
  // export const VAR_MSISDN_COUNTRY_CODE = 964;
  // export const VAR_MSISDN_REGEX_AC = "(7[0123456789][0123456789])";
  // export const VAR_MSISDN_LENGTH_WITHOUT_CC_AND_AC = 7;
  // export const VAR_MSISDN_MIN_LENGTH = 13;
  // export const VAR_MSISDN_MAX_LENGTH = 13;
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .matches(
        phoneRegExp2,
        intl.formatMessage({
          id: "AUTH.VALIDATION.INVALID_PHONE",
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    // .min(
    //   13,
    //   intl.formatMessage({
    //     id: "AUTH.VALIDATION.TEL.MIN_LENGTH_FIELD",
    //   })
    // )
    // .max(
    //   13,
    //   intl.formatMessage({
    //     id: "AUTH.VALIDATION.TEL.MAX_LENGTH_FIELD",
    //   })
    // ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });
  const LoginOTPSchema = Yup.object().shape({
    username: Yup.string()
      .matches(
        phoneRegExp2,
        intl.formatMessage({
          id: "AUTH.VALIDATION.INVALID_PHONE",
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });
  const SignUpSchema = Yup.object().shape({
    msisdn: Yup.string()
      .matches(
        phoneRegExp2,
        intl.formatMessage({
          id: "AUTH.VALIDATION.INVALID_PHONE",
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    // .min(
    //   13,
    //   intl.formatMessage({
    //     id: "AUTH.VALIDATION.TEL.MIN_LENGTH_FIELD",
    //   })
    // )
    // .max(
    //   13,
    //   intl.formatMessage({
    //     id: "AUTH.VALIDATION.TEL.MAX_LENGTH_FIELD",
    //   })
    // ),
  });
  const PinCodeSchema = Yup.object().shape({
    pincode: Yup.string(),
    msisdn: Yup.string(),
  });
  const ValidateOTPSchema = Yup.object().shape({
    pincode: Yup.string(),
  });
  const CreatePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        intl.formatMessage({ id: "AUTH.WRONG_PASS" })
        // "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null],
        // "Passwords must match"
        intl.formatMessage({ id: "AUTH.PASSWORD_NOT_MATCH" })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const CompleteProfileSchema = Yup.object().shape({
    firstName: Yup.string().trim().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    lastName: Yup.string().trim().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    fatherName: Yup.string().trim().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    email: Yup.string().trim()
      .email("Wrong email format")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    // password: Yup.string().required(
    //   intl.formatMessage({
    //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //   })
    // ),
    // confirmPassword: Yup.string().required(
    //   intl.formatMessage({
    //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //   })
    // ),
    dateOfBirth: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    genderId: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    idLocation: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    invitationCode: Yup.string(),
  });
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const getInputClassesComplete = (fieldname) => {
    if (
      formikCompleteProfile.touched[fieldname] &&
      formikCompleteProfile.errors[fieldname]
    ) {
      return "is-invalid";
    }

    if (
      formikCompleteProfile.touched[fieldname] &&
      !formikCompleteProfile.errors[fieldname]
    ) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.username.toString(), Encrypt(values.password))
          .then((data) => {
            console.log(data)
            if (data.error) {
              disableLoading();
              setStatus(data.message);
            } else {
              disableLoading();
              if (data.data.statusCode.code == 0) { 
                KTCookie.setCookie("idUserType", "2");
                props.login(data);
                history.push("/my-korek/dashboard");
              }
              else if (data.data.statusCode.code == 978) {
                setmsisdn(values.username);
                setIdRequest(data.data.idRequest);
                setShow(false);
                setValidateOTP(true);
              }
              // else {
              //   enqueueSnackbar(data.data.statusCode.message, { variant: "warning", autoHideDuration: 7000 });
              // }
              // KTCookie.setCookie("idUserType", "2");
              // props.login(data);
              // history.push("/my-korek/dashboard");
              // return  <Redirect to="/my-korek/dashboard" />;
            }
            // redirect("/my-korek/dashboard");
          })
          .catch((data) => {
            disableLoading();
            setStatus(data.message);
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
      // window.location.href = "/my-korek";
    },
  });
  const formikOtp = useFormik({
    initialValues,
    validationSchema: LoginOTPSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        loginOTP(values.username.toString())
          .then((data) => {
            if (data.error) {
              disableLoading();
              setStatus(data.message);
            } else {
              console.log(data)
              disableLoading();
              setmsisdn(values.username);
              setIdRequest(data.data.idrequest);
              setShow(false);
              setValidateOTP(true);
              // KTCookie.setCookie("idUserType", "2");
              // props.login(data);
              // history.push("/my-korek/dashboard");
              // return  <Redirect to="/my-korek/dashboard" />;
            }
            // redirect("/my-korek/dashboard");
          })
          .catch((data) => {
            setStatus(data.message);
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
      // window.location.href = "/my-korek";
    },
  });
  const formikValidateOTP = useFormik({
    initialValuesPin,
    enableReinitialize: "true",
    validationSchema: ValidateOTPSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      enableLoading();
      let params = {
        username: msisdnVal.toString(),
        pincode: EncryptOTP(pinValues.join("")),
        idrequest: idRequest,
      };

      console.log(formik.values)
      let headers = {
        idusertype: "3",
        otp: EncryptOTP(pinValues.join("")),
        idrequest: idRequest,
      };
      setTimeout(() => {
        if (loginMethod === 'password') {
          let paramsPass = params
          paramsPass.password = Encrypt(formik.values.password)
          validateLoginPass(paramsPass, headers)
            .then((data) => {
              if (data.error) {
                disableLoading();
                setStatus(data.message);
              } else {
                if (data.data.statusCode.code == 0) {
                  disableLoading();
                  KTCookie.setCookie("idUserType", "2");
                  setPinValues(["", "", "", ""])
                  props.login(data);
                  history.push("/my-korek/dashboard");
                  setValidateOTP(false);
                }
                else if (data.data.statusCode.code == 111) {
                  // setValidateOTP(false);
                  enqueueSnackbar(data.data.statusCode.message, {
                    variant: "warning",
                  });
                }
                else {

                  enqueueSnackbar(data.data.statusCode.message, {
                    variant: "warning", autoHideDuration: 7000

                  });
                }
              }
            })
            .catch((data) => {
              setStatus(data.message);
            })
            .finally(() => {
              disableLoading();
              setSubmitting(false);
            });
        } else if (loginMethod === 'otp') {
          validateLoginOtp(params, headers)
            .then((data) => {
              if (data.error) {
                disableLoading();
                setStatus(data.message);
              } else {
                if (data.data.statusCode.code == 0) {
                  disableLoading();
                  KTCookie.setCookie("idUserType", "2");
                  setPinValues(["", "", "", ""])
                  props.login(data);
                  history.push("/my-korek/dashboard");
                  setValidateOTP(false);
                }

                else if (data.data.statusCode.code == 111) {
                  // setValidateOTP(false);
                  enqueueSnackbar(data.data.statusCode.message, {
                    variant: "warning",
                  });
                }
                else {

                  enqueueSnackbar(data.data.statusCode.message, {
                    variant: "warning", autoHideDuration: 7000

                  });
                }
              }
            })
            .catch((data) => {
              setStatus(data.message);
            })
            .finally(() => {
              disableLoading();
              setSubmitting(false);
            });
        }

      }, 1000);
    },
  });
  const formikResendCode = useFormik({
    initialValues,
    // validationSchema: SignUpSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        resendCode(values.msisdn.toString())
          .then((data) => {
            // setmsisdn(values.msisdn)
            // console.log(data)
            if (data.error) {
              disableLoading();
              setStatus(data.message);
            } else if (data.data.statusCode.code == 0) {
              // KTCookie.setCookie("token",data.data.uid)
              // setUID(data.data.uid)
              // setSignUp(false)
              // setVerifyNbr(true)
              enqueueSnackbar(data.data.statusCode.message, {
                variant: "success",
              });
            } else {
              disableLoading();
              enqueueSnackbar(data.data.statusCode.message, {
                variant: "warning",
              });
              // setStatus(data.data.statusCode.message);
            }
            // redirect("/my-korek/dashboard");
          })
          .catch((data) => {
            enqueueSnackbar(data.message, {
              variant: "danger",
            });
            setStatus(data.message);
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });
  const formikSignUp = useFormik({
    initialValues,
    validationSchema: SignUpSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        signUp(values.msisdn.toString())
          .then((data) => {
            setmsisdn(values.msisdn);
            if (data.error) {
              disableLoading();
              setStatus(data.message);
            } else if (data.data.statusCode.code == 0) {
              KTCookie.setCookie("token", data.data.uid);
              setUID(data.data.uid);
              setSignUp(false);
              setVerifyNbr(true);
            } else {
              disableLoading();
              setStatus(data.data.statusCode.message);
            }
            // redirect("/my-korek/dashboard");
          })
          .catch((data) => {
            setStatus(data.message);
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });
  const formikPinCode = useFormik({
    initialValuesPin,
    enableReinitialize: "true",
    validationSchema: PinCodeSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      let params = {
        msisdn: msisdnVal.toString(),
        pincode: pinValues.join(""),
      };
      setTimeout(() => {
        pinCodeVerification(params)
          .then((data) => {
            if (data.error) {
              disableLoading();
              setStatus(data.message);
            } else {
              setVerifyNbr(false);
              setCreatePass(true);
            }
          })
          .catch((data) => {
            setStatus(data.message);
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });
  const formikCreatePassword = useFormik({
    initialValues: initialValuesPass,
    validationSchema: CreatePasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // console.log(values)
      enableLoading();
      setPassword(Encrypt(values.password));
      setConfirmPassword(Encrypt(values.confirmPassword));
      disableLoading();
      setSubmitting(false);
      setCreatePass(false);
      setCompleteProfile(true);
    },
  });
  const formikCompleteProfile = useFormik({
    initialValues: initialValuesCompleteProfile,
    validationSchema: CompleteProfileSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      values.password = passwordVal;
      values.confirmPassword = confirmPasswordVal;
      // formikCompleteProfile.setFieldValue("msisdn",values.msisdn.toString()) 
      values.uid = uid;
      setTimeout(() => {
        completeProfile(values)
          .then((data) => {
            if (data.error) {
              disableLoading();
              setStatus(data.message);
            } else {
              setCompleteProfile(false);
              setLoggingIn(true);
              enableLoading();
              setTimeout(() => {
                login(msisdnVal.toString(), values.password)
                  .then((data) => {
                    if (data.error) {
                      disableLoading();
                      setStatus(data.message);
                    } else {
                      disableLoading();
                      props.login(data);
                      // history.push("/my-korek/dashboard");
                      setLoggingIn(false);
                      // window.location.href = "/my-korek/dashboard";
                      // return  <Redirect to="/my-korek/dashboard" />;
                    }
                    // redirect("/my-korek/dashboard");
                  })
                  .catch((data) => {
                    setStatus(data.message);
                  })
                  .finally(() => {
                    disableLoading();
                    setSubmitting(false);
                  });
              }, 1000);
            }
            // redirect("/my-korek/dashboard");
          })
          .catch((data) => {
            setStatus(data.message);
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });
  const formikForgetPass = useFormik({
    initialValues,
    validationSchema: SignUpSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        forgetPasswordPincode(values.msisdn.toString())
          .then((data) => {
            setmsisdn(values.msisdn);
            if (data.error) {
              disableLoading();
              setStatus(data.message);
            } else {
              setUserID(data.data.idUser);
              setForgetPass(false);
              setForgetPassVerify(true);
            }
            // redirect("/my-korek/dashboard");
          })
          .catch((data) => {
            setStatus(data.message);
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });
  const formikResendCodeForgetPass = useFormik({
    initialValues: initialValuesResendCodeForgetPass,
    // validationSchema: SignUpSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {

        resendCodeForgetPass(formikForgetPass.values.msisdn.toString())
          .then((data) => {
            // setmsisdn(values.msisdn)
            // console.log(data)
            if (data.error) {
              disableLoading();
              setStatus(data.message);
            } else if (data.data.statusCode.code == 0) {
              // KTCookie.setCookie("token",data.data.uid)
              // setUID(data.data.uid)
              // setSignUp(false)
              // setVerifyNbr(true)
              enqueueSnackbar(data.data.statusCode.message, {
                variant: "success",
              });
            } else {
              disableLoading();
              enqueueSnackbar(data.data.statusCode.message, {
                variant: "warning",
              });
              // setStatus(data.data.statusCode.message);
            }
            // redirect("/my-korek/dashboard");
          })
          .catch((data) => {
            enqueueSnackbar(data.message, {
              variant: "danger",
            });
            setStatus(data.message);
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });
  const formikPinForgetPassCode = useFormik({
    initialValuesPin,
    enableReinitialize: "true",
    validationSchema: PinCodeSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      let params = {
        msisdn: msisdnVal.toString(),
        pincode: pinValuesForgetPass.join(""),
      };
      setTimeout(() => {
        forgetPasswordPincodeVerification(params)
          .then((data) => {
            if (data.error) {
              disableLoading();
              setStatus(data.message);
            } else {
              setUID(data.data.uid);
              KTCookie.setCookie("token", data.data.uid);
              setForgetPassVerify(false);
              setCreatePassForgetPass(true);
            }
          })
          .catch((data) => {
            setStatus(data.message);
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });
  const formikCreatePasswordForgetPass = useFormik({
    initialValues: initialValuesPass,
    validationSchema: CreatePasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // console.log(values)
      let params = {
        password: Encrypt(values.password),
      };
      enableLoading();
      setTimeout(() => {
        resetPasswordPincode(params)
          .then((data) => {
            if (data.error) {
              disableLoading();
              setStatus(data.message);
            } else {
              setPassword(Encrypt(values.password));
              disableLoading();
              setSubmitting(false);
              setStatus(data.data.statusCode.message);
            }
          })
          .catch((data) => {
            setStatus(data.message);
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  // const setHidden = () => {
  //   console.log(document.getElementById("root").style.overflow);
  //   // if (document.body.style.overflow !== "hidden") {
  //     // document.body.style.overflow = "hidden";
  //   // } else {
  //     // document.html.style.overflow = "hidden"
  //     // document.body.style.overflow = "hidden";
  //     // document.getElementsByClassName("modal-open")[0].classList("scrolllllllllllllllllllllllllllllllllllllllllllll")
  //     // document.getElementById("root").style.overflow = "hidden"
  //     // document.getElementById("kt_body").style.overflow = "hidden"
  //   // }
  // };
  // const setScroll = () => {
  //   console.log(document.getElementById("root").style.overflow);
  //   // if (document.body.style.overflow !== "hidden") {
  //     // document.body.style.overflow = "hidden";
  //   // } else {
  //     document.body.style.overflow = "";
  //     document.getElementById("root").style.overflow = ""
  //   // }
  // };
  const dispatch = useDispatch()
  useEffect(() => {
    show &&
      // window.scrollTo(0, 0)
      // setHidden()
      sendRequestDotNet("Profile/LoadData", {}, {}).then((response) => {
        setLocations(response.data.locations);
        setGenders(response.data.genders);
      });
  }, [show]);

  const [loginMethod, setLoginMethod] = useState('password'); // 'password' or 'otp'

  useEffect(() => {
    loginCheck && setShow(true)
    loginCheck && dispatch(setLogin(!loginCheck))
    loginCheck && console.log(moment().valueOf())
    loginCheck && console.log(show)
  }, [loginCheck]);

  const classes = useStyles();
  return (
    <>
      {layoutProps.offcanvas && user && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}
        >
          <div className="topbar-item">
            <div
              className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <>
                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                  Hi,
                </span>
                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                  {user.fullname}
                </span>
                <span className="symbol symbol-35 symbol-light-success">
                  <span className="symbol-label font-size-h5 font-weight-bold">
                    {user.fullname[0]}
                  </span>
                </span>
              </>
            </div>
          </div>
        </OverlayTrigger>
      )}
      {!layoutProps.offcanvas && user && <UserProfileDropdown />}
      {!layoutProps.offcanvas && !user && (

        <Button
          onClick={() => {
            formik.resetForm();
            setShow(true);
          }}
          style={{ height: "max-content", width: "max-content", alignSelf: "center" }}
          className="btn btn-sm btn-warning"
        >
          <b>
            <FormattedMessage id="AUTH.LOGIN" />
          </b>
        </Button>
      )}
      <Modal backdrop="static" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton className="justify-content-end" style={{ borderBottom: '0px' }}></Modal.Header>
        {/* Radio Button Group */}
        <div className="text-center modalHeader mb-10 mb-lg-20">
          <h3 className="font-size-h3">
            <b>
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </b>
          </h3>
          <p className="text-muted font-weight-bold">
            <FormattedMessage id="AUTH.WITH" />
          </p>
        </div>
        <div className="form-group">
          <div className="radio-inline">
            <label className="radio">
              <input
                type="radio"
                value="password"
                checked={loginMethod === 'password'}
                onChange={() => setLoginMethod('password')}
              />
              <span></span>
              <FormattedMessage id="AUTH.LOGIN" />
            </label>
            <label className="radio">
              <input
                type="radio"
                value="otp"
                checked={loginMethod === 'otp'}
                onChange={() => setLoginMethod('otp')}
              />
              <span></span>
              <FormattedMessage id="AUTH.LOGIN.TITLE.BY_OTP" />
            </label>
          </div>
        </div>
        {loginMethod === 'password' && (
          <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
            <Modal.Body>


              {/* Conditional Form Fields */}
              <>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder={intl.formatMessage({ id: "AUTH.ENTER_K_NBR" })}
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("username")}`}
                    name="username"
                    {...formik.getFieldProps("username")}
                  />
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder={intl.formatMessage({ id: "AUTH.ENTER_PASS" })}
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("password")}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                </div>
              </>


              {/* Error Handling */}
              {formik.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
              )}

              <div className="form-group fv-plugins-icon-container">
                <div className="checkbox-inline">
                  <label className="checkbox m-0">
                    <input
                      type="checkbox"
                      checked={showPassword}
                      onChange={() => setShowPassword(!showPassword)}
                    />
                    <span></span>
                    <FormattedMessage id="AUTH.VALIDATION.SHOW_PASS" />
                  </label>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer style={{ borderTop: '0px' }} className="justify-content-center">
              <Row>
                <Col xl={12} className="text-center">
                  <Button
                    className="mx-3"
                    variant="warning"
                    disabled={formik.isSubmitting}
                    type="submit"
                  >
                    <b>
                      <FormattedMessage id="AUTH.LOGIN" />
                    </b>
                    {loading && <span className="mx-3 spinner spinner-white"></span>}
                  </Button>
                </Col>
                <Col xl={12}>
                  <div
                    onClick={() => {
                      setShow(false);
                      setSignUp(true);
                      // Reset all forms here as needed
                    }}
                    className="dark-blue text-hover-primary text-center d-pointer pt-5"
                  >
                    <FormattedMessage id="AUTH.DONT_HAVE_ACC" />
                    <b>
                      <FormattedMessage id="AUTH.SIGN_UP" />
                    </b>
                  </div>
                </Col>
              </Row>
            </Modal.Footer>
          </form>
        )}

        {loginMethod === 'otp' && (<form onSubmit={formikOtp.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
          <Modal.Body>

            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={intl.formatMessage({ id: "AUTH.ENTER_K_NBR" })}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("username")}`}
                name="username"
                {...formikOtp.getFieldProps("username")}
              />
            </div>
            {/* Error Handling */}
            {formikOtp.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formikOtp.status}</div>
              </div>
            )}

          </Modal.Body>

          <Modal.Footer style={{ borderTop: '0px' }} className="justify-content-center">
            <Row>
              <Col xl={12} className="text-center">
                <Button
                  className="mx-3"
                  variant="warning"
                  disabled={formikOtp.isSubmitting}
                  type="submit"
                >
                  <b>
                    <FormattedMessage id="AUTH.LOGIN" />
                  </b>
                  {loading && <span className="mx-3 spinner spinner-white"></span>}
                </Button>
              </Col>
              <Col xl={12}>
                <div
                  onClick={() => {
                    setShow(false);
                    setSignUp(true);
                    // Reset all forms here as needed
                  }}
                  className="dark-blue text-hover-primary text-center d-pointer pt-5"
                >
                  <FormattedMessage id="AUTH.DONT_HAVE_ACC" />
                  <b>
                    <FormattedMessage id="AUTH.SIGN_UP" />
                  </b>
                </div>
              </Col>
            </Row>
          </Modal.Footer>
        </form>
        )}

      </Modal>
      <Modal
        backdrop="static"
        show={showSignUp}
        onHide={() => {
          // setScroll()
          setSignUp(false);
          formikSignUp.resetForm();
        }}
      >
        <Modal.Header
          className="justify-content-end"
          style={{ borderBottom: "0px" }}
          closeButton
        ></Modal.Header>
        <form
          // method="post"
          //  onSubmit={e => { e.preventDefault(); }}
          onSubmit={formikSignUp.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <Modal.Body>
            <div className="text-center modalHeader mb-10 mb-lg-20">
              <h3 className="font-size-h3">
                <b>
                  <FormattedMessage id="AUTH.LOGIN.SIGN_UP" />
                </b>
              </h3>
              <p className="font-weight-bold">
                <FormattedMessage id="AUTH.WITH" />
              </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            {formikSignUp.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formikSignUp.status}
                </div>
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={intl.formatMessage({ id: "Holder.EnterMobileNumber" })}
                // type="number"
                className={`form-control form-control-solid tel-input h-auto py-5 px-6 ${getInputClasses(
                  "msisdn"
                )}`}
                name="msisdn"
                {...formikSignUp.getFieldProps("msisdn")}
              />
              <Form.Text
                id="passwordHelpBlock"
                className="voucher-helper"
                muted
              >
                {intl.formatMessage({ id: "HOME.RECHARGE.MSISDN.HELPER" })}
              </Form.Text>
              {formikSignUp.touched.msisdn && formikSignUp.errors.msisdn ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formikSignUp.errors.msisdn}
                  </div>
                </div>
              ) : null}
            </div>
          </Modal.Body>

          <Modal.Footer
            style={{ borderTop: "0px" }}
            className="justify-content-center"
          >
            <Row className="">
              <Col xl={12} className="text-center">
                <Button
                  className="mx-auto text-center"
                  // onClick={() => formikSignUp.handleSubmit()}
                  id="kt_signup_submit"
                  variant="warning"
                  type="submit"
                  disabled={formikSignUp.isSubmitting}
                >
                  <b>
                    <FormattedMessage id="AUTH.NEXT" />
                  </b>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </Button>
              </Col>
              <Col xl={12}>
                {" "}
                <div
                  onClick={() => {
                    setShow(true);

                    formikSignUp.resetForm();
                    setSignUp(false);
                  }}
                  className="dark-blue text-hover-primary text-center d-pointer pt-5"
                >
                  <FormattedMessage id="AUTH.ALREADY_HAVE_ACC" />{" "}
                  <b>
                    <FormattedMessage id="AUTH.SIGN_IN" />
                  </b>
                </div>
              </Col>
              {/* <Button variant="secondary" onClick={() => setShow(false)}>
              <b>Close</b>
            </Button> */}
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        backdrop="static" show={showVerifyNbr} onHide={() => {
          // setScroll()
          setVerifyNbr(false)
        }}>
        <Modal.Header
          className="justify-content-end"
          style={{ borderBottom: "0px" }}
          closeButton
        ></Modal.Header>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          // onSubmit={formikPinCode.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <Modal.Body>
            <div className="text-center modalHeader mb-10 mb-lg-20">
              <h3 className="font-size-h3">
                <b>
                  <FormattedMessage id="AUTH.LOGIN.SIGN_UP" />
                </b>
              </h3>
              <p className="font-weight-bold">
                <FormattedMessage id="AUTH.VERIFY_NBR" />
              </p>
            </div>
            <p className="text-center">
              {intl.formatMessage({ id: "AUTH.PLEASE_ENTER_VER" }) +
                "“" +
                msisdnVal +
                "”"}
            </p>
            {/* end::Head */}

            {/*begin::Form*/}
            {formikPinCode.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formikPinCode.status}
                </div>
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">
              <PinInput
                placeholder="."
                values={pinValues}
                size="lg"
                onChange={(value, index, values) => {
                  formikPinCode.setValues("pincode", pinValues);
                  // formikPinCode.setFieldValue("pincode",values);
                  setPinValues(values);
                }}
              />
              {/* {formikPinCode.touched.pincode && formikPinCode.errors.pincode ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formikPinCode.errors.pincode}</div>
                </div>
              ) : null} */}
            </div>
          </Modal.Body>

          <Modal.Footer
            style={{ borderTop: "0px" }}
            className="justify-content-center"
          >
            <Row
              className=""
              style={{
                justifyContent: "center",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              // onSubmit={formikPinCode.handleSubmit}
              >
                <Col className="text-center" xl={12}>
                  {loading ? (
                    <span
                      style={{ paddingRight: "17px" }}
                      className="spinner spinner-warning"
                    ></span>
                  ) : (
                    <div
                      onClick={() => {
                        formikResendCode.setFieldValue(
                          "msisdn",
                          formikSignUp.values.msisdn
                        );
                        formikResendCode.handleSubmit();
                      }}
                      className="dark-blue text-hover-primary text-center d-pointer pb-5"
                    >
                      <b>
                        <FormattedMessage id="AUTH.RESEND_CODE"></FormattedMessage>
                      </b>
                    </div>
                  )}
                </Col>
              </form>

              <Col xl={12} className="text-center mt-5">
                <Button
                  className="mx-2 text-center"
                  onClick={() => {
                    setSignUp(true);
                    setVerifyNbr(false);
                  }}
                  id="kt_login_signin_submit"
                  variant="light"
                  disabled={formikSignUp.isSubmitting}
                >
                  <b>
                    <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON" />
                  </b>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </Button>
                <Button
                  className="mx-auto text-center"
                  // type="submit"
                  onClick={() => formikPinCode.handleSubmit()}
                  id="kt_login_signin_submit"
                  variant="warning"
                  disabled={
                    formikPinCode.isSubmitting || pinValues.join("").length < 4
                  }
                >
                  <b>
                    <FormattedMessage id="AUTH.VERIFY"></FormattedMessage>
                  </b>
                  {loading && (
                    <span className="mx-3 spinner spinner-white spinner-center"></span>
                  )}
                </Button>
              </Col>
              {/* <Button variant="secondary" onClick={() => setShow(false)}>
              <b>Close</b>
            </Button> */}
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        backdrop="static" show={showCreatePass} onHide={() => setCreatePass(false)}>
        <Modal.Header
          className="justify-content-end"
          style={{ borderBottom: "0px" }}
          closeButton
        ></Modal.Header>
        <form
          // onSubmit={(e) => {
          //   e.preventDefault();
          // }}
          onSubmit={formikCreatePassword.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <Modal.Body>
            <div className="text-center modalHeader mb-10 mb-lg-20">
              <h3 className="font-size-h3">
                <b>
                  <FormattedMessage id="AUTH.LOGIN.SIGN_UP" />
                </b>
              </h3>
              <p className="font-weight-bold">
                <FormattedMessage id="AUTH.CREATE_PASS"></FormattedMessage>
              </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            {formikCreatePassword.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formikCreatePassword.status}
                </div>
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">
              <input
                name="password"
                values={passwordVal}
                onChange={(e) =>
                  formikCreatePassword.setFieldValue("password", e.target.value)
                }
                autocomplete="new-password"
                placeholder={intl.formatMessage({ id: "AUTH.PASS" })}
                type="password"
                className={`form-control form-control-solid cust-input h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                {...formikCreatePassword.getFieldProps("password")}
              />
              {formikCreatePassword.touched.password &&
                formikCreatePassword.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formikCreatePassword.errors.password}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="form-group fv-plugins-icon-container">
              <input
                name="confirmPassword"
                values={confirmPasswordVal}
                onChange={(e) =>
                  formikCreatePassword.setFieldValue(
                    "confirmPassword",
                    e.target.value
                  )
                }
                autocomplete="new-password"
                placeholder={intl.formatMessage({ id: "AUTH.CONFIRM_PASS" })}
                type="password"
                className={`form-control form-control-solid cust-input h-auto py-5 px-6 ${getInputClasses(
                  "confirmPassword"
                )}`}
                {...formikCreatePassword.getFieldProps("confirmPassword")}
              />
              {formikCreatePassword.touched.confirmPassword &&
                formikCreatePassword.errors.confirmPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formikCreatePassword.errors.confirmPassword}
                  </div>
                </div>
              ) : null}
            </div>
          </Modal.Body>

          <Modal.Footer
            style={{ borderTop: "0px" }}
            className="justify-content-center"
          >
            <Row className="">
              <Col xl={12} className="text-center">
                <Button
                  className="mx-auto text-center"
                  type="submit"
                  // onClick={() => formikCreatePassword.handleSubmit()}
                  id="kt_login_signin_submit"
                  variant="warning"
                  disabled={formikCreatePassword.isSubmitting}
                >
                  <b>
                    <FormattedMessage id="AUTH.SUBMIT" />
                  </b>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </Button>
              </Col>
              {/* <Button variant="secondary" onClick={() => setShow(false)}>
              <b>Close</b>
            </Button> */}
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        backdrop="static"
        show={showCompleteProfile}
        onHide={() => setCompleteProfile(false)}
      >
        <Modal.Header
          className="justify-content-end"
          style={{ borderBottom: "0px" }}
          closeButton
        ></Modal.Header>
        <form
          // onSubmit={(e) => {
          //   e.preventDefault();
          // }}
          onSubmit={formikCompleteProfile.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <Modal.Body>
            <div className="text-center modalHeader mb-10 mb-lg-20">
              <h3 className="font-size-h3">
                <b>
                  <FormattedMessage id="AUTH.LOGIN.SIGN_UP" />
                </b>
              </h3>
              <p className="font-weight-bold">
                <FormattedMessage id="AUTH.COMPLETE_PROF" />
              </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <FormikProvider value={formikCompleteProfile}>
              {formikCompleteProfile.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formikCompleteProfile.status}
                  </div>
                </div>
              )}
              {/* <pre>{JSON.stringify(formikCompleteProfile.errors, null, 2)}</pre> */}
              <div className="form-group fv-plugins-icon-container">
                <Form.Label as="label">
                  <b>
                    {intl.formatMessage({
                      id: "AUTH.FIRST_NAME",
                    })}
                  </b>
                </Form.Label>
                <input
                  type="text"
                  // onChange={(e)=>formikCompleteProfile.setFieldValue('firstName',e.target.value)}
                  placeholder={intl.formatMessage({
                    id: "AUTH.FIRST_NAME",
                  })}
                  className={`form-control form-control-lg form-control-solid ${getInputClassesComplete(
                    "firstName"
                  )}`}
                  name="firstName"
                  {...formikCompleteProfile.getFieldProps("firstName")}
                />
                {formikCompleteProfile.touched.firstName &&
                  formikCompleteProfile.errors.firstName ? (
                  <div className="invalid-feedback">
                    {formikCompleteProfile.errors.firstName}
                  </div>
                ) : null}
              </div>
              <div className="form-group fv-plugins-icon-container">
                <Form.Label as="label">
                  <b>
                    {intl.formatMessage({
                      id: "AUTH.LAST_NAME",
                    })}
                  </b>
                </Form.Label>
                <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "AUTH.LAST_NAME",
                  })}
                  className={`form-control form-control-lg form-control-solid ${getInputClassesComplete(
                    "lastName"
                  )}`}
                  name="lastName"
                  {...formikCompleteProfile.getFieldProps("lastName")}
                />

                {formikCompleteProfile.touched.lastName &&
                  formikCompleteProfile.errors.lastName ? (
                  <div className="invalid-feedback display-block">
                    {formikCompleteProfile.errors.lastName}
                  </div>
                ) : null}
              </div>
              <div className="form-group fv-plugins-icon-container">
                <Form.Label as="label">
                  <b>
                    {intl.formatMessage({
                      id: "AUTH.FATHER_NAME",
                    })}
                  </b>
                </Form.Label>
                <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "AUTH.FATHER_NAME",
                  })}
                  className={`form-control form-control-lg form-control-solid ${getInputClassesComplete(
                    "fatherName"
                  )}`}
                  name="fatherName"
                  {...formikCompleteProfile.getFieldProps("fatherName")}
                />
                {formikCompleteProfile.touched.fatherName &&
                  formikCompleteProfile.errors.fatherName ? (
                  <div className="invalid-feedback">
                    {formikCompleteProfile.errors.fatherName}
                  </div>
                ) : null}
              </div>

              <div className="form-group fv-plugins-icon-container">
                <Form.Label as="label">
                  <b>
                    {intl.formatMessage({
                      id: "AUTH.EMAIL",
                    })}
                  </b>
                </Form.Label>
                <input
                  type="email"
                  placeholder={intl.formatMessage({
                    id: "AUTH.EMAIL",
                  })}
                  className={`form-control form-control-lg form-control-solid ${getInputClassesComplete(
                    "email"
                  )}`}
                  name="email"
                  {...formikCompleteProfile.getFieldProps("email")}
                />
                {formikCompleteProfile.touched.email &&
                  formikCompleteProfile.errors.email ? (
                  <div className="invalid-feedback display-block">
                    {formikCompleteProfile.errors.email}
                  </div>
                ) : null}
              </div>

              <div className="form-group fv-plugins-icon-container">
                <Row className="px-5">
                  <Form.Label className="mr-3" as="label">
                    <b>
                      <FormattedMessage id="AUTH.GENDER"></FormattedMessage>:
                      {"  "}
                    </b>
                  </Form.Label>
                  {genders &&
                    genders.map((x) => (
                      <Form.Check
                        onChange={() =>
                          formikCompleteProfile.setFieldValue(
                            "genderId",
                            x.idParam
                          )
                        }
                        className="px-5"
                        type="radio"
                        label={x.value}
                        name="formHorizontalRadios"
                        id={x.value}
                      />
                    ))}
                </Row>
              </div>
              <div className="form-group fv-plugins-icon-container">
                <Row className="px-5">
                  <Form.Label className="mr-3" as="label">
                    <b>
                      <FormattedMessage id="AUTH.DOB"></FormattedMessage>:{"  "}
                    </b>
                  </Form.Label>
                </Row>
                <TextField
                  onChange={(e) =>
                    formikCompleteProfile.setFieldValue(
                      "dateOfBirth",
                      e.target.value
                    )
                  }
                  id="date"
                  // label="Date Of Birth"
                  type="date"
                  name="dateOfBirth"
                  // defaultValue="2017-05-24"


                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    // min: "2020-08-10",
                    max: moment().format("yyyy-MM-DD")
                  }}
                />
                {formikCompleteProfile.touched.dateOfBirth &&
                  formikCompleteProfile.errors.dateOfBirth ? (
                  <div className="invalid-feedback">
                    {formikCompleteProfile.errors.dateOfBirth}
                  </div>
                ) : null}
              </div>
              <div className="form-group fv-plugins-icon-container">
                <Form.Label as="label">
                  <b>
                    {intl.formatMessage({
                      id: "AUTH.LOCATION",
                    })}
                  </b>
                </Form.Label>
                <select
                  className="form-control form-control-lg form-control-solid"
                  name="idLocation"
                  {...formikCompleteProfile.getFieldProps("idLocation")}
                >
                  <option>
                    {intl.formatMessage({
                      id: "AUTH.SELECT_LOCATION",
                    })}
                  </option>
                  {locations &&
                    locations.map((x) => (
                      <option value={x.idParam}>{x.value}</option>
                    ))}
                </select>
                {formikCompleteProfile.touched.idLocation &&
                  formikCompleteProfile.errors.idLocation ? (
                  <div className="invalid-feedback">
                    {formikCompleteProfile.errors.idLocation}
                  </div>
                ) : null}
              </div>
              <div className="form-group fv-plugins-icon-container">
                <Form.Label as="label">
                  <b>{intl.formatMessage({ id: "AUTH.INVITATION_CODE" })}</b>
                </Form.Label>
                <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "AUTH.INVITATION_CODE",
                  })}
                  className={`form-control form-control-lg form-control-solid ${getInputClassesComplete(
                    "invitationCode"
                  )}`}
                  name="invitationCode"
                  {...formikCompleteProfile.getFieldProps("invitationCode")}
                />
                {formikCompleteProfile.touched.invitationCode &&
                  formikCompleteProfile.errors.invitationCode ? (
                  <div className="invalid-feedback">
                    {formikCompleteProfile.errors.invitationCode}
                  </div>
                ) : null}
              </div>
            </FormikProvider>
          </Modal.Body>

          <Modal.Footer
            style={{ borderTop: "0px" }}
            className="justify-content-center"
          >
            <Row className="">
              <Col xl={12} className="text-center">
                <Button
                  className="mx-auto text-center"
                  type="submit"
                  // onClick={() => formikCompleteProfile.handleSubmit()}
                  id="kt_login_signin_submit"
                  variant="warning"
                  disabled={formikCompleteProfile.isSubmitting}
                >
                  <b>{intl.formatMessage({ id: "AUTH.SUBMIT" })}</b>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        backdrop="static" show={showLoggingIn} onHide={() => setLoggingIn(false)}>
        <Modal.Header
          className="justify-content-end"
          style={{ borderBottom: "0px" }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className="text-center modalHeader mb-10 mb-lg-20">
            <h3 className="font-size-h3">
              <b>
                <FormattedMessage id="AUTH.LOGIN.LOGIN_IN" />
              </b>
            </h3>
          </div>
          {/* end::Head */}

          {loading && (
            <div className="overlay-layer">
              <div className="spinner spinner-lg spinner-primary" />
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static" show={showForgetPass} onHide={() => setForgetPass(false)}>
        <Modal.Header
          className="justify-content-end"
          style={{ borderBottom: "0px" }}
          closeButton
        ></Modal.Header>
        <form
          // onSubmit={(e) => {
          //   e.preventDefault();
          // }}
          onSubmit={formikForgetPass.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <Modal.Body>
            <div className="text-center modalHeader mb-10 mb-lg-20">
              <h3 className="font-size-h3">
                <b>
                  <FormattedMessage id="AUTH.LOGIN.FORGET_PASS" />
                </b>
              </h3>
              {/* <p className="font-weight-bold">With Korek Number</p> */}
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            {formikForgetPass.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formikForgetPass.status}
                </div>
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={intl.formatMessage({ id: "Holder.EnterMobileNumber" })}
                type="number"
                className={`form-control tel-input form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "msisdn"
                )}`}
                name="msisdn"
                {...formikForgetPass.getFieldProps("msisdn")}
              />
              <Form.Text
                id="passwordHelpBlock"
                className="voucher-helper"
                muted
              >
                {intl.formatMessage({ id: "HOME.RECHARGE.MSISDN.HELPER" })}
              </Form.Text>
              {formikForgetPass.touched.msisdn &&
                formikForgetPass.errors.msisdn ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formikForgetPass.errors.msisdn}
                  </div>
                </div>
              ) : null}
            </div>
          </Modal.Body>

          <Modal.Footer
            style={{ borderTop: "0px" }}
            className="justify-content-center"
          >
            <Row className="">
              <Col xl={12} className="text-center">
                <Button
                  className="mx-auto text-center"
                  type="submit"
                  // onClick={() => formikForgetPass.handleSubmit()}
                  id="kt_login_signin_submit"
                  variant="warning"
                  disabled={formikForgetPass.isSubmitting}
                >
                  <b>
                    <FormattedMessage id="AUTH.SEND_PINCODE" />
                  </b>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </Button>
              </Col>
              <Col xl={12}>
                {" "}
                <div
                  onClick={() => {
                    setShow(true);
                    setForgetPass(false);
                  }}
                  className="dark-blue text-hover-primary text-center d-pointer pt-5"
                >
                  <FormattedMessage id="AUTH.DONT_HAVE_ACC" />{" "}
                  <b>
                    <FormattedMessage id="AUTH.SIGN_IN" />
                  </b>
                </div>
              </Col>
              {/* <Button variant="secondary" onClick={() => setShow(false)}>
              <b>Close</b>
            </Button> */}
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        backdrop="static"
        show={showValidateOTP}
        onHide={() => setValidateOTP(false)}
      >
        <Modal.Header
          className="justify-content-end"
          style={{ borderBottom: "0px" }}
          closeButton
        ></Modal.Header>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          // onSubmit={formikPinForgetPassCode.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <Modal.Body>
            <div className="text-center modalHeader mb-10 mb-lg-20">
              <h3 className="font-size-h3">
                <b>
                  <FormattedMessage id="AUTH.LOGIN" />

                </b>
              </h3>
              <p className="font-weight-bold">
                {/* <FormattedMessage id="AUTH.VERIFY_NBR" /> */}
                Verify OTP
              </p>
            </div>
            <p className="text-center">
              {intl.formatMessage({ id: "AUTH.ENTER_VER_CODE" }) +
                "“" +
                msisdnVal +
                "”"}
            </p>
            {/* end::Head */}

            {/*begin::Form*/}
            {formikPinForgetPassCode.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formikPinForgetPassCode.status}
                </div>
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">
              <PinInput
                placeholder="."
                values={pinValuesForgetPass}
                size="lg"
                onChange={(value, index, values) => {
                  formikPinForgetPassCode.setValues(
                    "pincode",
                    pinValuesForgetPass
                  );
                  // formikPinCode.setFieldValue("pincode",values);
                  setPinValuesForgetPass(values);
                }}
              />
              {/* {formikPinCode.touched.pincode && formikPinCode.errors.pincode ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formikPinCode.errors.pincode}</div>
                </div>
              ) : null} */}
            </div>
          </Modal.Body>

          <Modal.Footer
            style={{ borderTop: "0px" }}
            className="justify-content-center"
          >
            <Row className="">
              <Col xl={12} className="text-center mt-5">
                <Button
                  className="mx-auto text-center"
                  // type="submit"
                  onClick={() => formikPinForgetPassCode.handleSubmit()}
                  id="kt_login_signin_submit"
                  variant="warning"
                  disabled={
                    formikPinForgetPassCode.isSubmitting ||
                    pinValuesForgetPass.join("").length < 4
                  }
                >
                  <b>
                    <FormattedMessage id="AUTH.VERIFY" />
                  </b>
                  {loading && (
                    <span className="mx-3 spinner spinner-white spinner-center"></span>
                  )}
                </Button>
              </Col>
              {/* <Button variant="secondary" onClick={() => setShow(false)}>
              <b>Close</b>
            </Button> */}
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        backdrop="static"
        show={showCreatePassForgetPass}
        onHide={() => setCreatePassForgetPass(false)}
      >
        <Modal.Header
          className="justify-content-end"
          style={{ borderBottom: "0px" }}
          closeButton
        ></Modal.Header>
        <form
          // onSubmit={(e) => {
          //   e.preventDefault();
          // }}
          onSubmit={formikCreatePasswordForgetPass.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <Modal.Body>
            <div className="text-center modalHeader mb-10 mb-lg-20">
              <h3 className="font-size-h3">
                <b>
                  <FormattedMessage id="AUTH.LOGIN.FORGET_PASS" />
                </b>
              </h3>
              <p className="font-weight-bold">
                <FormattedMessage id="AUTH.CREATE_PASS" />
              </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            {formikCreatePasswordForgetPass.status && (
              <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formikCreatePasswordForgetPass.status}
                </div>
              </div>
            )}
            {!formikCreatePasswordForgetPass.status && (
              <>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    name="password"
                    values={passwordVal}
                    onChange={(e) =>
                      formikCreatePasswordForgetPass.setFieldValue(
                        "password",
                        e.target.value
                      )
                    }
                    autocomplete="new-password"
                    placeholder={intl.formatMessage({ id: "AUTH.PASS" })}
                    type="password"
                    className={`form-control form-control-solid cust-input h-auto py-5 px-6 ${getInputClasses(
                      "password"
                    )}`}
                    {...formikCreatePasswordForgetPass.getFieldProps(
                      "password"
                    )}
                  />
                  {formikCreatePasswordForgetPass.touched.password &&
                    formikCreatePasswordForgetPass.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formikCreatePasswordForgetPass.errors.password}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <input
                    name="confirmPassword"
                    values={confirmPasswordVal}
                    onChange={(e) =>
                      formikCreatePasswordForgetPass.setFieldValue(
                        "confirmPassword",
                        e.target.value
                      )
                    }
                    autocomplete="new-password"
                    placeholder={intl.formatMessage({
                      id: "AUTH.CONFIRM_PASS",
                    })}
                    type="password"
                    className={`form-control form-control-solid cust-input h-auto py-5 px-6 ${getInputClasses(
                      "confirmPassword"
                    )}`}
                    {...formikCreatePasswordForgetPass.getFieldProps(
                      "confirmPassword"
                    )}
                  />
                  {formikCreatePasswordForgetPass.touched.confirmPassword &&
                    formikCreatePasswordForgetPass.errors.confirmPassword ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formikCreatePasswordForgetPass.errors.confirmPassword}
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </Modal.Body>

          <Modal.Footer
            style={{ borderTop: "0px" }}
            className="justify-content-center"
          >
            <Row className="">
              {formikCreatePasswordForgetPass.status ? (
                <Col xl={12} className="text-center">
                  <Button
                    className="mx-auto text-center"
                    onClick={() => {
                      setCreatePassForgetPass(false);
                      setShow(true);
                    }}
                    variant="warning"
                  >
                    <b>
                      <FormattedMessage id="AUTH.CLOSE" />
                    </b>
                  </Button>
                </Col>
              ) : (
                <Col xl={12} className="text-center">
                  <Button
                    className="mx-auto text-center"
                    type="submit"
                    // onClick={() => formikCreatePasswordForgetPass.handleSubmit()}
                    id="kt_login_signin_submit"
                    variant="warning"
                    disabled={formikCreatePasswordForgetPass.isSubmitting}
                  >
                    <b>
                      <FormattedMessage id="AUTH.SUBMIT" />
                    </b>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </Button>
                </Col>
              )}

              {/* <Button variant="secondary" onClick={() => setShow(false)}>
              <b>Close</b>
            </Button> */}
            </Row>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        backdrop="static" show={showValidateOTP} onHide={() => {
          // setScroll()
          setValidateOTP(false)
        }}>
        <Modal.Header
          className="justify-content-end"
          style={{ borderBottom: "0px" }}
          closeButton
        ></Modal.Header>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          // onSubmit={formikPinCode.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <Modal.Body>
            <div className="text-center modalHeader mb-10 mb-lg-20">
              <h3 className="font-size-h3">
                <b>
                  <FormattedMessage id="AUTH.SIGN_IN" />
                </b>
              </h3>
              <p className="font-weight-bold">
                <FormattedMessage id="AUTH.VERIFY_NBR" />
              </p>
            </div>
            <p className="text-center">
              {intl.formatMessage({ id: "AUTH.PLEASE_ENTER_VER" }) +
                "“" +
                msisdnVal +
                "”"}
            </p>
            {/* end::Head */}

            {/*begin::Form*/}
            {formikValidateOTP.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formikValidateOTP.status}
                </div>
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">
              <PinInput
                placeholder="."
                values={pinValues}
                size="lg"
                onChange={(value, index, values) => {
                  formikValidateOTP.setValues("pincode", pinValues);
                  // formikPinCode.setFieldValue("pincode",values);
                  setPinValues(values);
                }}
              />
              {/* {formikPinCode.touched.pincode && formikPinCode.errors.pincode ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formikPinCode.errors.pincode}</div>
                </div>
              ) : null} */}
            </div>
          </Modal.Body>

          <Modal.Footer
            style={{ borderTop: "0px" }}
            className="justify-content-center"
          >
            <Row
              className=""
              style={{
                justifyContent: "center",
              }}
            >

              <Col xl={12} className="text-center mt-5">
                <Button
                  className="mx-auto text-center"
                  // type="submit"
                  onClick={() => formikValidateOTP.handleSubmit()}
                  id="kt_login_signin_submit"
                  variant="warning"
                  disabled={
                    formikValidateOTP.isSubmitting || pinValues.join("").length < 4
                  }
                >
                  <b>
                    <FormattedMessage id="AUTH.VERIFY"></FormattedMessage>
                  </b>
                  {loading && (
                    <span className="mx-3 spinner spinner-white spinner-center"></span>
                  )}
                </Button>
              </Col>
              {/* <Button variant="secondary" onClick={() => setShow(false)}>
              <b>Close</b>
            </Button> */}
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
